import type { MetaDescriptor } from '@remix-run/node'
import type { ServerRuntimeMetaArgs, SerializeFrom } from '@remix-run/server-runtime'
import type { loader as rootLoader } from '~/root'

type Props = {
  args: ServerRuntimeMetaArgs
  title?: string
  description?: string
  ogImage?: string
  ogType?: 'website' | 'article'
  twitterCard?: 'summary_large_image' | 'summary' | 'player' | 'app'
  canonical?: string
  robots?: 'noindex' | 'nofollow' | 'noindex, nofollow'
  prev?: string
  next?: string
}

export const siteName = 'AUBA for Startup'
// export const baseTitle = `${siteName} | 日本最大級のオープンイノベーションプラットフォーム`
export const baseTitle = siteName
export const baseDescription =
  'これまで数多くのオープンイノベーション支援をしてきたeiicon。オープンイノベーションをより加速化させるため、新たな支援策として、資金調達ピッチ「The Commit」、オープンイノベーションピッチ「The Scale」などを開始。より多くのスタートアップの皆さまに挑戦の機会を創出します！'

export const getMetadata = ({
  args,
  title,
  description = baseDescription,
  ogImage,
  ogType = 'website',
  twitterCard = 'summary_large_image',
  canonical,
  robots,
  prev,
  next,
}: Props): MetaDescriptor[] => {
  const { matches, location } = args
  const matchData = matches.find((match) => {
    return match.id === 'root'
  })?.data as SerializeFrom<typeof rootLoader>
  const env = matchData.env
  const { AUBA_STARTUP_URL, NO_INDEX } = env
  const titleText = title ? `${title} | ${siteName}` : baseTitle
  const canonicalUrl = canonical ?? `${AUBA_STARTUP_URL}${location.pathname}`
  const ogImageUrl = ogImage ?? `${AUBA_STARTUP_URL}/assets/img/img-ogp.png`

  const metadata: MetaDescriptor[] = [
    {
      title: titleText,
    },
    {
      name: 'description',
      content: description,
    },
    {
      tagName: 'link',
      rel: 'canonical',
      href: canonicalUrl,
    },
    {
      property: 'og:title',
      content: titleText,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:url',
      content: canonicalUrl,
    },
    {
      property: 'og:site_name',
      content: siteName,
    },
    {
      property: 'og:image',
      content: ogImageUrl,
    },
    {
      property: 'og:type',
      content: ogType,
    },
    {
      name: 'twitter:card',
      content: twitterCard,
    },
    {
      name: 'twitter:title',
      content: titleText,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: ogImageUrl,
    },
  ]

  if (process.env.NODE_ENV !== 'production' || NO_INDEX === 'true') {
    metadata.push({
      name: 'robots',
      content: 'noindex, nofollow',
    })
  } else if (robots) {
    metadata.push({
      name: 'robots',
      content: robots,
    })
  }

  if (prev) {
    metadata.push({
      tagName: 'link',
      rel: 'prev',
      href: prev,
    })
  }

  if (next) {
    metadata.push({
      tagName: 'link',
      rel: 'next',
      href: next,
    })
  }

  return metadata
}
