import { type CSSProperties, type ReactNode } from 'react'
import * as styles from './style.css'

type Props = {
  children: ReactNode
  className?: string
  isTopNoSpace?: boolean
  isBottomNoSpace?: boolean
  style?: CSSProperties
}

export const LayoutPageWrapper = ({ children, className, isTopNoSpace, isBottomNoSpace, style }: Props) => {
  return (
    <div
      className={[
        styles.layoutPageWrapper,
        isTopNoSpace && styles.layoutPageWrapper__topNoSpace,
        isBottomNoSpace && styles.layoutPageWrapper__bottomNoSpace,
        className,
      ]
        .filter(Boolean)
        .join(' ')}
      style={style}
    >
      {children}
    </div>
  )
}
